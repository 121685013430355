import * as React from 'react';

export function CancomLogo(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="svg3429"
      viewBox="-0.74399997 -0.74399997 156.08800994 26.28799894"
      {...props}
    >
      <defs id="defs3431" />
      <path
        style={{ fill: '#db052c', fillOpacity: 1 }}
        clipPath="none"
        id="path3368"
        d="m 41.6,24.2 7.5,0 -10.2,-23.9 -6.4,0 -9.9,23.9 7.2,0 5.8,-15.4 6,15.4 z m 78.3,-11.9 c 0,3.5 -2.4,6.5 -6.1,6.5 -3.6,0 -6.1,-3 -6.1,-6.5 0,-3.5 2.4,-6.5 6,-6.5 3.6,0 6.2,3.1 6.2,6.5 m 6.7,0.1 C 126.6,5.5 121.3,0 113.8,0 106.4,0 101,5.6 101,12.4 c 0,6.9 5.3,12.4 12.8,12.4 7.4,0 12.8,-5.6 12.8,-12.4 M 83,12.5 C 83,9 85.4,6 88.9,6 c 1.2,0 2.3,0.3 3.2,0.8 L 94.7,1.5 C 93.5,0.7 91.5,0 88.8,0 81.6,0 76.3,5.6 76.3,12.4 c 0,7.2 5,12.3 12.5,12.3 6.8,0 10.3,-4.2 11.4,-8.3 l -5.4,-2.2 c -0.7,2.3 -2.5,4.6 -5.7,4.6 C 85.4,18.8 83,16 83,12.5 m -76.2,0 C 6.8,9 9.2,6 12.7,6 13.9,6 15,6.3 15.9,6.8 L 18.5,1.5 C 17.3,0.7 15.3,0 12.6,0 5.3,0 0,5.6 0,12.4 c 0,7.2 5,12.3 12.5,12.3 6.8,0 10.3,-4.2 11.4,-8.3 l -5.4,-2.2 c -0.7,2.3 -2.5,4.6 -5.7,4.6 -3.6,0 -6,-2.8 -6,-6.3 m 60.9,11.7 5.7,0 0,-23.7 -6.4,0 0,13.1 -9.9,-13.1 -6.1,0 0,23.8 6.4,0 0,-13.5 10.3,13.4 z m 86.9,0 0,-23.7 -7,0 -5.7,9.2 -5.7,-9.2 -7,0 0,23.8 6.5,0 0,-13.6 6.1,9.2 0.1,0 6.1,-9.3 0,13.7 6.6,-0.1 0,0 z"
        className="st0"
      />
    </svg>
  );
}

export function CDCLogo(props) {
  return (
    <svg
      version="1.1"
      id="Ebene_1"
      x="0px"
      y="0px"
      viewBox="0 0 292 45.2"
      style={{ enableBackground: 'new 0 0 292 45.2' }}
      {...props}
    >
      <g>
        <g>
          <path d="M59.8,9.5L59.8,9.5c0-5.3,4-9.5,9.5-9.5C73,0,75.4,1.6,77,3.8l-3.8,2.9c-1-1.3-2.2-2.1-4-2.1c-2.5,0-4.3,2.2-4.3,4.8v0.1c0,2.7,1.8,4.9,4.3,4.9c1.9,0,3-0.9,4.1-2.2l3.8,2.7c-1.7,2.4-4,4.1-8,4.1C64,18.9,59.8,14.9,59.8,9.5z" />
          <path d="M84.9,11.7L78,0.4h5.7L87.4,7l3.8-6.6h5.6l-6.9,11.3v6.9h-5V11.7z" />
          <path d="M99.1,0.4h9.3c2.3,0,3.9,0.6,5,1.6c0.7,0.8,1.2,1.7,1.2,3V5c0,2.1-1.2,3.3-2.8,4c2.2,0.7,3.6,2,3.6,4.4v0.1c0,3.1-2.5,5-6.9,5h-9.4V0.4z M109.6,6c0-1-0.8-1.6-2.3-1.6H104v3.2h3.2C108.7,7.6,109.6,7.1,109.6,6L109.6,6z M107.9,11.1H104v3.3h3.9c1.6,0,2.4-0.6,2.4-1.7v-0.1C110.4,11.8,109.6,11.1,107.9,11.1z" />
          <path d="M118.5,0.4h14.6v4.3h-9.6v2.8h8.7v4h-8.7v2.9h9.8v4.3h-14.7V0.4z" />
          <path d="M136.6,0.4h8.6c2.8,0,4.7,0.7,5.9,1.9c1.1,1.1,1.6,2.4,1.6,4.2v0.1c0,2.8-1.5,4.6-3.7,5.6l4.3,6.3h-5.8l-3.7-5.5h-2.2v5.5h-5V0.4z M145,9.1c1.7,0,2.7-0.8,2.7-2.2V6.9c0-1.5-1.1-2.2-2.7-2.2h-3.3v4.4H145z" />
          <path d="M164,0.4h7c6.5,0,10.2,3.7,10.2,9v0.1c0,5.2-3.8,9.1-10.3,9.1H164V0.4z M169,4.8v9.3h2c3,0,5-1.7,5-4.6V9.4c0-2.9-2-4.6-5-4.6H169z" />
          <path d="M184.6,0.4h14.6v4.3h-9.6v2.8h8.7v4h-8.7v2.9h9.8v4.3h-14.7V0.4z" />
          <path d="M202.7,0.4h14.5v4.4h-9.5v3.1h8.6V12h-8.6v6.5h-5V0.4z" />
          <path d="M220.3,0.4h14.6v4.3h-9.6v2.8h8.7v4h-8.7v2.9h9.8v4.3h-14.7V0.4z" />
          <path d="M238.4,0.4h4.7l7.5,9.6V0.4h5v18.2h-4.4l-7.8-10v10h-5V0.4z" />
          <path d="M258.2,15.8l2.8-3.3c1.8,1.4,3.8,2.2,5.9,2.2c1.4,0,2.1-0.5,2.1-1.2v-0.1c0-0.8-0.6-1.2-3.1-1.8c-3.9-0.9-6.8-2-6.8-5.7V5.9c0-3.4,2.7-5.8,7-5.8c3.1,0,5.5,0.8,7.5,2.4L271,6c-1.7-1.2-3.5-1.8-5.1-1.8c-1.2,0-1.8,0.5-1.8,1.2v0.1c0,0.8,0.6,1.2,3.2,1.8c4.2,0.9,6.7,2.3,6.7,5.7v0.1c0,3.7-2.9,5.9-7.3,5.9C263.5,18.8,260.5,17.8,258.2,15.8z" />
          <path d="M277.3,0.4h14.6v4.3h-9.6v2.8h8.7v4h-8.7v2.9h9.8v4.3h-14.7V0.4z" />
          <path d="M59.8,35.4L59.8,35.4c0-5.3,4-9.5,9.5-9.5c3.7,0,6.1,1.6,7.7,3.8l-3.8,2.9c-1-1.3-2.2-2.1-4-2.1c-2.5,0-4.3,2.2-4.3,4.8v0.1c0,2.7,1.8,4.9,4.3,4.9c1.9,0,3-0.9,4.1-2.2l3.8,2.7c-1.7,2.4-4,4.1-8,4.1C64,44.8,59.8,40.9,59.8,35.4z" />
          <path d="M80.1,26.3h14.6v4.3h-9.6v2.8h8.7v4h-8.7v2.9h9.8v4.3H80.1V26.3z" />
          <path d="M98.2,26.3h4.7l7.5,9.6v-9.6h5v18.2H111l-7.8-10v10h-5V26.3z" />
          <path d="M123.8,30.7h-5.4v-4.4h15.9v4.4h-5.4v13.7h-5V30.7z" />
          <path d="M137.3,26.3h14.6v4.3h-9.6v2.8h8.7v4h-8.7v2.9h9.8v4.3h-14.7V26.3z" />
          <path d="M155.4,26.3h8.6c2.8,0,4.7,0.7,5.9,1.9c1.1,1.1,1.6,2.4,1.6,4.2v0.1c0,2.8-1.5,4.6-3.7,5.6l4.3,6.3h-5.8l-3.7-5.5h-2.2v5.5h-5V26.3z M163.8,35c1.7,0,2.7-0.8,2.7-2.2v-0.1c0-1.5-1.1-2.2-2.7-2.2h-3.3V35H163.8z" />
        </g>
      </g>
      <g>
        <path d="M17.7,12.6c0.5-0.3,1.1-0.3,1.6,0l17,10.1c0.8-3.4,1.2-7,0.9-10.7c0-0.6-0.3-1.1-0.7-1.5c-4-3.8-16.9-9.9-17.3-10c-0.4-0.1-0.8-0.1-1.1,0c-1.2,0.4-14,6.9-17.3,10c-0.4,0.4-0.7,0.9-0.7,1.5c-0.2,2.5-0.2,6.3,0.9,10.7L17.7,12.6z" />
        <path d="M19.5,19.3c-0.6-0.4-1.3-0.4-1.9,0L2.7,28.2c0,0,0,0,0,0c4.1,9.7,11.3,14.6,14.8,16.6c0.7,0.4,1.5,0.4,2.2,0c6.1-3.3,11.6-9.3,14.7-16.7L19.5,19.3z" />
      </g>
    </svg>
  );
}