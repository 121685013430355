import { useState, useEffect } from 'react';
import ReactGA from 'react-ga';

export function useValidateResponses(answers, completed) {
  const [uiState, setUiState] = useState({ status: 'loading' });

  useEffect(() => {
    (async () => {
      const response = await fetch('/api/evaluate', {
        method: 'POST',
        body: JSON.stringify({ answers: answers, completed }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const responseData = await response.json();

      if (responseData.success) {
        ReactGA.event({
          category: 'Validated Responses',
          action: 'Success',
        });
        setUiState({ status: 'success', data: responseData });
      } else {
        ReactGA.event({
          category: 'Validated Responses',
          action: 'Failed',
        });
        setUiState({ status: 'failed' });
      }
    })();
  }, [answers, completed]);

  return uiState;
}
