import { Button } from '@material-ui/core';
import { useValidateResponses } from './useValidateResponses';
import { Confetti } from './Confetti';

export function ValidationScreen({
  handleClickShowToken,
  handleClickNextStage,
  answers,
  handleClickTryAgain,
  completed,
}) {
  const uiState = useValidateResponses(answers, completed);

  const renderSuccessScreen = () => {
    return (
      <div>
        <div className="screen_title">Congratulations, you have successfully completed the three mandatory stages 👏🏻👏🏻👏🏻</div>
        <div className="screen_success_description">
          <p>
            You already convinced us but we prepared more questions. The following two stages are
            optional and <b>do not</b> have to be completed successfully.
          </p>
          <p>
            <h3>Note:</h3>
            <p>
                By clicking on the <b>GIVE ME MY TOKEN</b> button, you will immediately receive the token and the challenge will end.
            </p>
            <p>
                By clicking on the button <b>SHOW ME MORE QUESTIONS</b> you will receive the token after completing the two optional stages.
            </p>
          </p>
        </div>
        <div className="buttons">
          <Button onClick={handleClickShowToken} variant="contained" color="secondary">
            Give me my token
          </Button>
          <div className="next_question">
            <Button onClick={handleClickNextStage} variant="contained" color="primary">
              Show me more questions
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const renderSuccessCompletedScreen = () => {
    return (
      <div>
        <div className="success_message">
          Congratulations, you have successfully completed the CDC challenge
          <br />
          <br /> Please send us your application with the token to{' '}
          <a href={'mailto:' + uiState.data.email + '?subject=CDC Challenge Winner&body=Token: ' + uiState.data.token}>{uiState.data.email}</a>
        </div>
        <div className="token">{uiState.data.token}</div>
        <div className="emoji">🎉</div>
        <Confetti />
      </div>
    );
  };

  const renderFailedScreen = () => {
    return (
      <div>
        <div className="screen_title">
           <img src="/Bill.jpg" alt="Bill" width="450px"></img>
        </div>
        <Button variant="contained" color="primary" onClick={handleClickTryAgain}>
          Try Again
        </Button>
      </div>
    );
  };

  if (uiState.status === 'success')
    return completed ? renderSuccessCompletedScreen() : renderSuccessScreen();

  if (uiState.status === 'failed') return renderFailedScreen();

  return <div>Loading...</div>;
}
