import VerticalStepper from './Stepper';

import { Card, CardContent } from '@material-ui/core';

export function Drawer({ steps, activeStepIndex }) {
  return (
    <Card variant="outlined" className="drawer">
      <CardContent>
        <VerticalStepper data={steps} activeStage={activeStepIndex} />
      </CardContent>
    </Card>
  );
}
