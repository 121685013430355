import React from 'react';
import PageLayout from './PageLayout';

export default function Impressum() {

    return (
        <PageLayout>
            <div style={{margin: "0 auto"}}>
                <h1>CANCOM Austria AG</h1>  <div><b>Address</b>: Wienerbergstraße 53, 1120 Vienna, Austria</div>  <div><b>Phone</b>: +43 50 822 0</div>  <div><b>Email</b>: info@cancom.com</div>  <div><b>Website</b>: www.cancom.at</div>  <hr /> <div>Company Register FN 178368 g</div> <div>Registered Office Vienna</div> <div>VAT: ATU46276408</div> <div>Member of the Chamber of Commerce of Vienna</div>
            </div>
        </PageLayout>
    );
}