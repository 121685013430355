import { useState } from 'react';
import { Card, CardContent, TextField, Button } from '@material-ui/core';
import PageLayout from './components/PageLayout';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Cancel';
import ReCAPTCHA from "react-google-recaptcha";

export default function Admin() {
  const [tokenContent, setTokenContent] = useState(null);
  return (
    <PageLayout>
      {tokenContent ? (
        <Report tokenContent={tokenContent} />
      ) : (
        <Form setTokenContent={setTokenContent} />
      )}
    </PageLayout>
  );
}

function Report({ tokenContent }) {
  const renderQuestion = (question) => {
    return (
      <TableRow key={question.description}>
        <TableCell component="th" scope="row" width="400px">
          {question.description}
        </TableCell>
        <TableCell width="200px">{question.userAnswer}</TableCell>
        <TableCell
          width="200px"
          dangerouslySetInnerHTML={{ __html: question.answers.join('</br>') }}
        ></TableCell>
        <TableCell width="100px">
          {question.correct ? (
            <CheckIcon style={{ color: 'green' }} />
          ) : question.userAnswer === '' ? (
            <CancelIcon style={{ color: 'orange' }} />
          ) : (
            <CancelIcon style={{ color: 'red' }} />
          )}
        </TableCell>
      </TableRow>
    );
  };

  const renderStage = (stage) => {
    return (
      <div>
        <h2>{stage.name}</h2>
        <Table className="" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Question</TableCell>
              <TableCell>Answer</TableCell>
              <TableCell>Correct Answers</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{stage.questions.map(renderQuestion)}</TableBody>
        </Table>
      </div>
    );
  };

  return (
    <Card style={{ margin: '15px auto' }}>
      <CardContent>{tokenContent.map(renderStage)}</CardContent>
    </Card>
  );
}

function Form({ setTokenContent }) {
  const [token, setToken] = useState('');
  const [password, setPassword] = useState('');
  const [recaptchaToken, setRecaptchaToken] = useState('');

  const handleClickSubmit = async () => {
    const response = await fetch('/api/admin', {
      method: 'POST',
      body: JSON.stringify({ token, password, "g-recaptcha-response":recaptchaToken }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const responseData = await response.json();

    setTokenContent(responseData);
  };

  return (
    <div className="form_wrapper">
      <Card>
        <CardContent className="form">
          <h2>Admin</h2>
          <div className="form_field">
            <TextField
              autocomplete="off"
              fullWidth={true}
              label="Token"
              variant="outlined"
              onChange={(e) => setToken(e.target.value)}
            />
          </div>
          <div className="form_field">
            <TextField
              autocomplete="off"
              fullWidth={true}
              label="Password"
              type="password"
              variant="outlined"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <ReCAPTCHA
            sitekey="6LeaJ7AaAAAAAHjk9ptqOHMAM-MXPIQ3aqZpNrEq"
            onChange={(value) => {
              console.log(value)
              setRecaptchaToken(value);
            }}
          />

          <div className="form_button">
            <Button onClick={handleClickSubmit} variant="contained" color="primary" disabled={recaptchaToken === ''}>
              Submit
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
