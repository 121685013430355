import { useEffect, useState, useRef } from 'react';
import { Card, CardContent, Button, TextField } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import InputMask from 'react-input-mask';
import PageLayout from './components/PageLayout';
import { Drawer } from './components/Drawer';
import { ValidationScreen } from './components/ValidationScreen';
import { HomeScreen } from './components/HomeScreen';

function App() {
  const [activeStageIndex, setActiveStageIndex] = useState(0);
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);
  const [showSuccessScreen, setShowSuccessScreen] = useState(false);
  const [stages, setStages] = useState(null);
  const [responses, setResponses] = useState([]);
  const [showValidationScreen, setShowValidationScreen] = useState(false);
  const [showStartScreen, setShowStartScreen] = useState(true);
  const inputRef = useRef(null);

  const getInitialResponses = (data) =>
    data.map(({ id, questions }) => ({
      id,
      answers: questions.map(() => ''),
    }));

  useEffect(() => {
    if (stages != null) {
      return;
    }
    (async () => {
      const response = await fetch('/api/questions');
      const data = await response.json();
      const initialRespones = getInitialResponses(data);
      setResponses(initialRespones);
      setStages(data);
    })();
  }, [stages]);

  if (!stages) return null;

  const currentResponse = responses[activeStageIndex].answers[activeQuestionIndex];
  const activeStage = stages[activeStageIndex];
  const activeQuestion = activeStage.questions[activeQuestionIndex];

  const isLastQuestion = activeStage.questions.length === activeQuestionIndex + 1;
  const isLastStage = activeStageIndex + 1 === stages.length;

  const handleClickPrevious = () => {
    if (activeQuestionIndex !== 0) {
      setActiveQuestionIndex(activeQuestionIndex - 1);
    }
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleClickNext = () => {
    if (isLastQuestion) {
      if (activeStage.validateResponses) {
        setShowValidationScreen(true);
      }

      if (isLastStage) {
        setShowSuccessScreen(true);
      } else {
        setActiveStageIndex(activeStageIndex + 1);
        setActiveQuestionIndex(0);
      }
    } else {
      setActiveQuestionIndex(activeQuestionIndex + 1);
    }
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleChangeInput = (e) => {
    const value = e.target.value;
    const newResponses = responses.map((stage, index) => {
      return {
        ...stage,
        answers:
          activeStageIndex === index
            ? stage.answers.map((answer, index) => (index === activeQuestionIndex ? value : answer))
            : [...stage.answers],
      };
    });

    setResponses(newResponses);
  };

  const renderButtons = () => {
    return (
      <div className="buttons">
        <div>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClickPrevious}
            disabled={activeQuestionIndex === 0}
          >
            Previous Question
          </Button>
        </div>
        <div className="next_question">
          <Button variant="contained" color="primary" onClick={handleClickNext}>
            {isLastQuestion ? 'Next Stage' : 'Next Question'}
          </Button>
        </div>
      </div>
    );
  };

  const renderQuestion = () => {
    return (
      <>
        <div className="question_count">
          {activeQuestionIndex + 1} / {activeStage.questions.length}
        </div>
        <Card className="question_card" variant="outlined" color="primary">
          <CardContent className="question_text">
            <HelpIcon className="icon" color="primary" style={{ fontSize: 40 }} />
            {activeQuestion.description}
          </CardContent>
          {activeQuestion.descriptionDetails ? (
            <div className="description_details">{activeQuestion.descriptionDetails}</div>
          ) : null}
        </Card>
        <div className="input">
          <InputMask
            mask={activeQuestion.inputMask}
            value={currentResponse}
            onChange={handleChangeInput}
          >
            {(inputProps) => (
              <TextField
                autoFocus={true}
                value={currentResponse}
                onChange={handleChangeInput}
                fullWidth={true}
                id="outlined-basic"
                label="Your answer"
                variant="outlined"
                inputRef={inputRef}
              />
            )}
          </InputMask>
        </div>
        {renderButtons()}
      </>
    );
  };

  const renderContent = () => {
    if (showStartScreen)
      return (
        <HomeScreen
          handleClickStartChallenge={() => {
            setShowStartScreen(false);
          }}
        />
      );
    if (showSuccessScreen) return <ValidationScreen answers={responses} completed={true} />;

    if (showValidationScreen)
      return (
        <ValidationScreen
          completed={false}
          answers={responses}
          handleClickShowToken={() => setShowSuccessScreen(true)}
          handleClickNextStage={() => setShowValidationScreen(false)}
          handleClickTryAgain={() => {
            setActiveStageIndex(0);
            setActiveQuestionIndex(0);
            setShowSuccessScreen(false);
            setResponses(getInitialResponses(stages));
            setShowValidationScreen(false);
          }}
        />
      );

    return renderQuestion();
  };

  return (
    <PageLayout showKapschInfo={showStartScreen}>
      {showStartScreen ? null : (
        <Drawer
          steps={stages}
          activeStepIndex={
            showSuccessScreen
              ? stages.length + 1
              : showValidationScreen
              ? activeStageIndex - 1
              : activeStageIndex
          }
        />
      )}
      <div className="main">{renderContent()}</div>
    </PageLayout>
  );
}

export default App;
