import { AppBar } from "@material-ui/core";

import { CancomLogo, CDCLogo } from "./Logo";
export default function PageLayout({ children, showKapschInfo }) {
  const renderTopBar = () => {
    return (
      <AppBar position="static" className="app_bar">
        <div className="navigation">
          <div className="logo">
            <a href="."><CDCLogo /></a>
          </div>
          <div className="logo">
            <CancomLogo />
          </div>
        </div>
      </AppBar>
    );
  };
  return (
    <div className="App">
      {renderTopBar()}
      <div className="content">{children}</div>
      <footer style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        {showKapschInfo ?
        <div>
          <h1 style={{marginTop: 0, paddingTop:0}}>CANCOM Cyber Defense Center</h1>{" "}
          <div>
            <b>Address</b>: Wienerbergstraße 53, 1120 Vienna, Austria
          </div>
          <div>
            <b>Email</b>: cdc@cancom.com
          </div>
          <div>
            <b>Website</b>: www.cancom.at
          </div>
        </div> : <div></div>}
        <div>
          <a className="footer-href" href="/impressum">Impressum</a> |{" "}
          <a className="footer-href" href="/cookie-policy"> Cookie Policy</a>
        </div>
      </footer>
    </div>
  );
}