import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

export default function VerticalStepper({ data, activeStage }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStage} orientation="vertical">
        {data.map((stage, index) => (
          <Step key={stage.id}>
            <StepLabel>
              <div className="step_label">{stage.name}</div>
            </StepLabel>
            <StepContent>
              <div className="step_text">{stage.description}</div>
              <div className="download_icon_wrapper">
                {stage.files?.map((file) => (
                  <a key={file} href={`/downloads/${file}`} target="/blank">
                    <div className="download_icon">
                      <GetAppIcon />
                      {file}
                    </div>
                  </a>
                ))}
              </div>
            </StepContent>
          </Step>
        ))}
        <Step>
          <StepLabel>
            <div className="step_label">Bewerbung</div>
          </StepLabel>
          <StepContent>
            <div className="step_text">
            </div>
          </StepContent>
        </Step>
      </Stepper>
    </div>
  );
}
