import React from 'react';
import PageLayout from './PageLayout';

export default function CookiePolicy () {

    return (
        <PageLayout>
        <div className="cookie_policy">
        <h1>Cookie Policy</h1>
        <h2>Cookies</h2>
            <p>We use cookies on our website. Cookies are small text files that are saved on your hard drive and associated with the browser used and that provide specific information to us. However, this does not mean that we receive knowledge of your identity in this way.</p>

            <p>The use of cookies helps us design our website to be easier for you to use. For instance, we create so-called session cookies to recognize that you have already visited the individual pages of our website. These are automatically deleted upon leaving our site.</p>

            <p>In addition, we also use temporary cookies that are saved on your device for a specifically defined period of time for optimizing the user-friendliness of the site. If you visit our site again to take advantage of our services, it is automatically detected that you were previously here, and the entries you made as well as settings you selected are loaded so that you do not have to enter them again.</p>

            <p>We also use cookies to collect statistics on the use of our website in order to evaluate these in the interests of optimizing the site and its content. These cookies allow us to automatically detect during a subsequent visit that you were previously here. These cookies are automatically deleted after a specifically defined period of time.</p>
                    
            <h2>Google Analytics</h2>

            <p>This website uses Google Analytics, a web analytics service of Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA (“Google”). We use Google Analytics in the interests of ensuring a user-friendly design and continuous optimization of our website as well as to record statistics on the use of our website in order to optimize our offering for you. The legal basis for this is Art. 6 para. 1 clause 1 item f GDPR.</p>

            <p>Google Analytics uses so-called “cookies”, text files that are saved on your computer and that enable analysis of your use of the website. The information generated by the cookie about your use of the website is generally transmitted to a server of Google in the USA and saved there. IP addresses are shortened and processed anonymously; no relationship to an individual person is therefore possible. If the data collected about you constitutes a personal reference, it is therefore immediately excluded and the personal data is immediately deleted. Only in exceptional cases is the complete IP address transmitted to a server of Google in the USA and shortened there.</p>

            <p>On behalf of the operator of this website, Google will use this information to analyze your use of the Website in order to compile reports on the website activities and to provide other services for the website operator in connection with use of the website and the internet (in particular, functions for display advertising, such as remarketing, reports on impressions in the Google Display Network, integration of DoubleClick Campaign Manager or Google Analytics reports on the performance based on demographic features and interests).</p>

            <p>Under some circumstances, Google can analyze your usage behavior over multiple devices (PC, smartphone, tablet, etc.) by means of so-called cross-device functions and evaluate this for us. In this regard, Google can combine information generated via the respective devices for the purposes of personalized advertising as well as with data from your Google account, if you have a Google account and your account settings have been configured such that Google can associate the browser history with the Google account and such that information from the Google account may be used for the personalization of advertisements.</p>

            <p>You can prevent the saving of cookies by configuring your browser software; however, please note that in this case you may not be able to make full use of all the functions of this website. In addition, you can prevent the collection of the data generated by the cookie and related to your use of the website (including your IP address) by Google and the processing of this data by Google by downloading and installing the browser plug-in available at the following link: <a href="https://tools.google.com/dlpage/gaoptout?hl=en">tools.google.com/dlpage/gaoptout?hl=en</a>.</p>

            <p>Third party provider information: Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA, terms of service: <a href="https://www.google.com/analytics/terms/us.html">www.google.com/analytics/terms/us.html</a>, data privacy overview: <a href="https://support.google.com/analytics/answer/6004245?hl=en">support.google.com/analytics/answer/6004245?hl=en</a>, and privacy statement: <a href="https://policies.google.com/privacy?hl=en">policies.google.com/privacy?hl=en</a>.</p>
                    
        </div> 
        </PageLayout>   
    );
}