import React from 'react';
import { useState } from 'react';
import Cookie from 'js-cookie';

export default function CookieBanner () {
    
    const [showBanner, setShowBanner] = useState(!Cookie.get('BannerCookie'));

    if(!showBanner)
    {
        return null;
    }

    return (
        <div className="cookie_banner">
           We use cookies on our website to ensure that we give you the best experience and to improve our website constantly, for statistical analyses and to adapt the content to your needs. To learn more about how we use cookies and how you can change your settings please <a href="/cookie-policy">click here!</a> If you continue your session on loglesen.at, you consent to the use of cookies.
           <svg xmlns="http://www.w3.org/2000/svg" onClick={() => { Cookie.set('BannerCookie', 1, {expires: 7}); setShowBanner(false)}} className="cookie_banner_close_button" viewBox="0 0 20 20" fill="currentColor">   <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" /> </svg>
        </div>    
    );
}