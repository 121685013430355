import { Button } from '@material-ui/core';
import CookieBanner from './CookieBanner';

export function HomeScreen(props) {
  return (
    <div className="home_screen">
      <div className="home_screen_h2">
        <h1>Dear log readers,</h1>
      </div>
      <div className="home_screen_text">
        <p>
          This challenge consists of several stations that test your analytical skills. At the end, you will receive a token that will allow you to apply for a job at the CANCOM Cyber Defense Center.
          There are a total of 5 stations with different questions and focuses. The <b>first 3 stations</b> are <b>obligatory</b>, i.e. all questions must be answered correctly. The  <b>last 2 stations</b> are <b>optional</b> and <b>do not</b>  have to be completed successfully, but allow us to get a better
          picture of your analytical skills. In order for certain stations to be successfully completed, various files must be downloaded and subsequently analysed. These files can be downloaded on the left side.{' '}
        </p>
      </div>
      <div className="home_screen_cdc_picture">
        <img src="/CANCOM_cdc_logo.png" alt="CDC"></img>
      </div>
      <div className="form_button">
        <Button onClick={props.handleClickStartChallenge} variant="contained" color="primary">
          Start the challenge
        </Button>
      </div>
        <CookieBanner></CookieBanner>
    </div>
  );
}
