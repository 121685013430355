import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import ReactGA from 'react-ga';
import Impressum from './components/Impressum';
import CookiePolicy from './components/CookiePolicy';

import Home from './Home';
import { theme } from './theme';

import './index.css';
import Admin from './Admin';

ReactGA.initialize('UA-196126650-1');

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Router>
      <Route path="/admin" exact={true}>
        <Admin />
      </Route>
      <Route path="/impressum" exact={true}>
        <Impressum/>
      </Route>
      <Route path="/cookie-policy" exact={true}>
        <CookiePolicy/>
     </Route>
      <Route path="/" exact={true}>
        <Home />
      </Route>
    </Router>
  </ThemeProvider>,
  document.getElementById('root')
);
